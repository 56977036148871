<template>

<app-wrapper class="team" v-touch:swipe.right="onBackClick" v-touch:swipe.left="onForwardClick">

	<app-wrapper :content="true" :text="true" class="team-content" :title="$state.page.team_title">

		<div class="team-content-list">

			<app-profile class="team-content-list-item" v-for="(item, index) in items" :key="index" :item="item" :class="{'is-active': index === active, 'is-left': index < active, 'is-right': index > active}" />

		</div>

		<div class="pips" v-if="window.is.mobile && items.length > 1">
			<div v-for="(item, index) in items" :key="index" class="pips-item" :class="{'is-active': index === active}" v-on:click="onJumpClick(index)" />
		</div>

		<router-link :to="{name: 'Team'}" class="team-content-more">{{ $state.page.team_button }}</router-link>

	</app-wrapper>

</app-wrapper>

</template>

<script>

import mixPips from '@/mixin/pips.js'

export default {

	mixins: [mixPips],

	computed: {

		items: function() {

			return this.$state.page.profiles
		
		}

	}

}

</script>

<style scoped>

.team {
	padding: 64px 0px;
}

.is-tablet .team {
	padding: 48px 0px;
}

.is-mobile .team {
	padding: 32px 0px;
}

.team-content-list {
	display: flex;
	margin: 64px auto;
	justify-content: center;
}

.is-tablet .team-content-list {
	margin: 48px auto;
}

.is-mobile .team-content-list {
	margin: 32px auto;
}

.team-content-list >>> .profile {
	margin: 0px 32px;
}

.team-content-list-item {
	transition: opacity 300ms ease-in-out;
}

.is-mobile .team-content-list-item:not(.is-active) {
	position: absolute;
	opacity: 0;
}

.is-mobile .team-content-list-item.is-active {
	position: relative;
	opacity: 1;
}

.team-content-more {
	margin: 0px auto;
	display: block;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 48px;
	border-radius: 24px;
	text-align: center;
    color: #FFF;
	background-color: #485CC7;
	width: 170px;
	height: 48px;
}

.is-mobile .team-content-more {
	height: 40px;
	line-height: 40px;
	width: 150px;
	font-size: 14px;
}

.team-content-more:hover {
	background-color: #3548ae;
}

.pips {
	display: flex;
	margin-top: 30px;
	margin-bottom: 30px;
}

.is-mobile .pips {
	justify-content: center;
}

.pips-item {
	border: 1px solid #485CC7;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
	transition: all 100ms linear;
	cursor: pointer;
}

.pips-item.is-active {
	background-color: #485CC7;
}

</style>
